<template>
	<b-container>
		<b-row>
			<b-col offset="5" class="py-4" style="color: white">
				logging out...<fa-icon icon="spinner" spin size="2x" />
			</b-col>
		</b-row>
	</b-container>
</template>
<script lang="js">
	import DEBUG from '@/common/DEBUG'

	export default {
		name: 'logout',

		async mounted() {
			const that = this
			that.$nextTick(async () => await that.logout())
		},

		methods: {
			async logout() {
				const that = this
				DEBUG.log('logging out...')
				await that.$store.dispatch('logout')
				await that.$router.push('/')
			}
		}
	}
</script>